import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PendingPage from "./Pending";
import UpcomingPage from "./Upcoming";
import CalendarPage from "./Calendar";
import DashboardPage from "./Dashboard";
import PastPage from "./Past";
import SameSchoolPage from "./SameSchool";
import RatePage from "./Rate";
import ContactsPage from "./Contacts";
import SearchPage from "./Search";
import MySessionsPage from "./MySessions";
import MyFavoritesPage from "./Favorites";
import BrowserCurriculumPage from "./Browser/Curriculum/index.js";
import BrowserPhotoPage from "./Browser/Photo/index";
import MyInfoPage from "./MyInfo";
import BestPracticePage from "./BestPractices";
import MystatsPage from "./Mystats";
import MySettingsPage from "./Mysettings";
import SurveyPage from "./Survey";
import MyPaymentsPage from "./MyPayments";
import ResourcePage from "./Resources";
import EventsPage from "./Events";
import PDWeekPage from "./PDWeek";
import CurriculumDetail from "./CurriculumDetail";
import BPReflection from "./BestPractices/reflection";
import BPCodeConduct from "./BestPractices/codeConduct";
import CancellationReschedulePolicy from "./BestPractices/crpolicy";
import BPIntroduction from "./BestPractices/introduction";
import StudentInteractivity from "./Resources/StudentInteractivity";
import TraumaInformedApproaches from "./Resources/TraumaInformedApproaches";
import CustomizedContent from "./Resources/CustomizedContent";
import ChatGPTActivity from "./ChatGPTActivity";
import AskAQuestion from './Questions';
import SHBestPracticeScreen from './SHBestPractices';
import SHAvailabilityScreen from './SHAvailability';
import { CreditDash, EarnMore } from "./Credit";
import AvailabileSessions from './AvailableSessions';
import Policies from './Policies';
import PDPlanReviewer from './PDPlan/PDReviewer';
import PDLogReviewer from './PDPlan/PDLogReviewer';
import BP2024Introduction from "./Best-Practices/2024/pages/introduction";
import BP2024Survey from "./Best-Practices/2024/pages/survey";
import BP2024Recording from "./Best-Practices/2024/pages/recording";
import BP2024Reflection from "./Best-Practices/2024/pages/reflection";
import BP2024LanguageRevitalization from "./Best-Practices/2024/pages/languageRevitalization";
import BP2024RoleExpectations from "./Best-Practices/2024/pages/roleExpectations";
import BP2024CancellationReschedulePolicy from "./Best-Practices/2024/pages/crpolicy";
import PrincipalReporting from "./PrincipalReporting/index.js";
import SchoolResources from "./SchoolResources";

export const CreditStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path="/cn/credits"
        exact
        render={() => <CreditDash {...props} />}
      />
      <Route
        path="/cn/credits/earn-more"
        exact
        render={() => <EarnMore {...props} />}
      />
      <Route
        path="/cn/credits/:teacherId"
        exact
        render={() => <CreditDash {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const UpcomingStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`/cn/upcoming`}
        exact
        render={(props) => <UpcomingPage {...props} />}
      />
      <Route
        path={`/cn/upcoming/past`}
        exact
        render={(props) => <PastPage {...props} />}
      />
      <Route
        path={`/cn/upcoming/same-school`}
        exact
        render={(props) => <SameSchoolPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const CalendarStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`/cn/calendar`}
        exact
        render={(props) => <CalendarPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const DashboardStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <DashboardPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const RateStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <RatePage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const PendingStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <PendingPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const ContactStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <ContactsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const EventsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <EventsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const SearchStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <SearchPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const MySessionsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MySessionsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const MySettingsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MySettingsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const MyInfoStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MyInfoPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BrowserCurriculumStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BrowserCurriculumPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BrowserPhotoStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BrowserPhotoPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const FavoritesStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MyFavoritesPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BestPracticeStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <BestPracticePage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BPIntroductionStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <BPIntroduction {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BPReflectionStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <BPReflection {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const BPCodeConductStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <BPCodeConduct {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const CancellationReschedulePolicyStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <CancellationReschedulePolicy {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const MystatsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MystatsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const SurveyStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <SurveyPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const MyPaymentsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <MyPaymentsPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const ResourceStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <ResourcePage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const PDWeekStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <PDWeekPage {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const CurriculumDetailStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <CurriculumDetail {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const StudentInteractivityStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <StudentInteractivity {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const TraumaInformedApproachStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <TraumaInformedApproaches {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const CreativeCustomizedContentStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <CustomizedContent {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const PoliciesStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <Policies {...props} />}
      />
    </Switch>
  </BrowserRouter>
);

export const ChatGPTActivityStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <ChatGPTActivity {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const AskQuestionStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <AskAQuestion {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const SHBestPracticeStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <SHBestPracticeScreen {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const SHAvailabilityStack = (props) => {
  // Import directly with 'import' for better error handling
  try {
    // Use the imported SHAvailabilityScreen from the top of the file
    const WeeklyScheduler = require('./SHAvailability/weekly-scheduler').default;
    
    // No debug logs needed in production
    
    // Using a simpler approach with less nesting
    return (
      <BrowserRouter>
        <Switch>
          <Route 
            path={`${props.match.path}/test`} 
            render={(routeProps) => <WeeklyScheduler {...routeProps} />} 
          />
          <Route 
            path={`${props.match.path}`} 
            render={(routeProps) => <SHAvailabilityScreen {...routeProps} />} 
          />
        </Switch>
      </BrowserRouter>
    );
  } catch (error) {
    console.error('Error in SHAvailabilityStack:', error);
    
    // Fallback component if imports fail
    const ErrorComponent = () => (
      <div style={{ padding: '20px', color: 'red' }}>
        <h2>Error Loading Component</h2>
        <p>There was an error loading the component: {error.message}</p>
      </div>
    );
    
    return (
      <BrowserRouter>
        <Switch>
          <Route path={`${props.match.path}`} component={ErrorComponent} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export const AvailableSessionsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <AvailabileSessions {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024IntroductionStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024Introduction {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024SurveyStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024Survey {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024ReflectionStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024Reflection {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024LanguageRevitalizationStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024LanguageRevitalization {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024RoleExpectationsStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024RoleExpectations {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024CancellationReschedulePolicyStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024CancellationReschedulePolicy {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const PDPlanReviewerStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <PDPlanReviewer {...props} />}
      />
    </Switch>
  </BrowserRouter>
)
export const PrincipalReportingStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <PrincipalReporting {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const PDLogReviewerStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        render={(props) => <PDLogReviewer {...props} />}
      />
    </Switch>
  </BrowserRouter>
)

export const BP2024RecordingStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <BP2024Recording {...props} />}
      />
    </Switch>
  </BrowserRouter>
)


export const SchoolResourceStack = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        path={`${props.match.path}`}
        exact
        render={(props) => <SchoolResources {...props} />}
      />
    </Switch>
  </BrowserRouter>
)
